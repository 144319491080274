import {del, get, post, put} from '../index';

export const rpaUpdate = (data) => {
    return put('/rpa/update', data).then((res) => res);
};

export const rpaAuthPage = (data) => {
    return post('/rpa/page', data).then((res) => res);
};

export const rpaAccountUpload = (data) => {
    return post('/rpa/upload', data).then((res) => res);
};

export const rpaAdd = (data) => {
    return post('/rpa/add', data).then((res) => res);
};
export const rpaDelete = (data) => {
    return del('/rpa/delete/' + data).then((res) => res);
};

export const rpaAuthGet = (data) => {
    return get('/rpa/get/' + data).then((res) => res);
};

export const rpaAuthLogin = (data) => {
    return post('/rpa/auth/login/', data, {timeout: 5 * 60 * 1000}).then((res) => res);
};
export const rpaAuthConfirm = (data) => {
    return post('/rpa/auth/confirm/', data).then((res) => res);
};

export const qrCodeLogin = (data) => {
    return post('/rpa/auth/qrCodeLogin/' + data).then((res) => res);
};

export const qrCode = (data) => {
    return post('/rpa/auth/qrCode/', data).then((res) => res);
};

export const saveRefUser = (data) => {
    return post('/rpa/auth/saveRefUser/', data).then((res) => res);
};

export const rpaGetDeFaultedByOrgId = (data) => {
    return get('/rpa/getDeFaultedByOrgId/' + data).then((res) => res);
};

export const getQrResult = (data) => {
    return get('/rpa/auth/getQrResult/' + data).then((res) => res);
};





